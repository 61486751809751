import { Container, Row, Col } from 'react-bootstrap';
import React, { useState, useEffect } from "react";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import image_url from "../../images/campaign_second/banner-image.jpg";
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from "jquery";

const CampaignHeader = ({ strapiId, processedImages, Banner_Content, Contact_Number }) => {
    return (
        <section className="banner">
            <div className="image">
                <img src={image_url} alt="Campaign Banner - Orlando Reid" />
            </div>
            <Container className="banner-content-block">
                <div className="details-text">
                    <h1>Unlike the rest.</h1>
                    <h2>Orlando Reid curated collection</h2>
                </div>
            </Container>
        </section>

    )
}

export default CampaignHeader;

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Slider from "react-slick";
import TeamImg1 from "../../images/campaign_second/Jack-team.jpg";
import TeamImg2 from "../../images/campaign_second/Armond-team.jpg";
import TeamImg3 from "../../images/campaign_second/Andrea-team.jpg";
import TeamImg4 from "../../images/campaign_second/Grace-team.jpg";
// import TeamImg5 from "../../images/campaign_second/Michail-team.jpg";
import "slick-carousel/slick/slick.css";
import { Link } from "gatsby";
import DateFormat from "../format-date";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";

const ImageSlider = (props) => {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    dots: true,
                    arrows: false
                }
            }
        ]
    };

    return (
        <section id='team-section' className="section-team">
            <Container className="teamSec">
                <div className="head-sec text-center">
                    <h2>The ‘A’ Team</h2>
                    <p>We combine the market knowledge and expertise of a large corporate estate agent with the personalised service and agility of an independent local team. We know Clapham and South West London like the back of our hand, allowing us to provide clients with the best possible guidance and results at every stage.</p>
                </div>
                <div className="team-slider">
                    <Slider {...settings}>

                        <div className={`content-block right-img`}>
                            <div className={`list`}>
                                <Row className={`list-row ellipse_bg`}>
                                    <Col md={6} className="order-md-2 even">
                                        <img src={TeamImg1} attr='Campaign List - Orlando Reid' />
                                    </Col>
                                    <Col md={6} className="order-md-1 odd">
                                        <div className='content'>
                                            <h5>Jack Reid</h5>
                                            <h6>FOUNDING DIRECTOR</h6>
                                            <p>Having started his career in an estate agency at a young age, Jack quickly excelled, becoming a partner within three years. After a period of travel, he co-founded Orlando Reid, an agency renowned for its client-centric approach and ethical practices. Driven by a passion for customer service and a desire to build a successful team, Jack continues to lead Orlando Reid towards its goal of becoming London's leading independent estate agency.</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <div className={`content-block right-img`}>
                            <div className={`list`}>
                                <Row className={`list-row ellipse_bg`}>
                                    <Col md={6} className="order-md-2 even">
                                        <img src={TeamImg2} attr='Campaign List - Orlando Reid' />
                                    </Col>
                                    <Col md={6} className="order-md-1 odd">
                                        <div className='content'>
                                            <h5>Armond Ghahramani</h5>
                                            <h6>DIRECTOR</h6>
                                            <p>As a Director at Orlando Reid, Armond oversees all aspects of the business, including client relations worldwide. With a career in property spanning over a decade, he brings a wealth of experience to the role. Having begun his journey in Nottingham, gaining a deep understanding of residential property, Armond landed multiple successful roles in London before joining Orlando Reid in 2017, finding his 'forever home' within the company.</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <div className={`content-block right-img`}>
                            <div className={`list`}>
                                <Row className={`list-row ellipse_bg`}>
                                    <Col md={6} className="order-md-2 even">
                                        <img src={TeamImg4} attr='Campaign List - Orlando Reid' />
                                    </Col>
                                    <Col md={6} className="order-md-1 odd">
                                        <div className='content'>
                                            <h5>Grace Turnbull</h5>
                                            <h6>Head Of Marketing</h6>
                                            <p>With 7 years of experience, Grace brings a blend of strategic thinking and creative flair to the team. Having worked in both purpose-built student accommodation and developer side selling luxury homes, she possesses a deep understanding of market nuances and how to effectively position properties to maximise client appeal. Grace understands that every property has its own unique story to tell, and believes in crafting bespoke marketing campaigns that truly shine.</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        {/* <div className={`content-block right-img`}>
                            <div className={`list`}>
                                <Row className={`list-row ellipse_bg`}>
                                    <Col md={6} className="order-md-2 even">
                                        <img src={TeamImg5} attr='Campaign List - Orlando Reid' />
                                    </Col>
                                    <Col md={6} className="order-md-1 odd">
                                        <div className='content'>
                                            <h5>Michail Stephani</h5>
                                            <h6>Sales Negotiator</h6>
                                            <p>Michail is a driven and results-oriented Sales Negotiator with 2 years of experience in the sales industry. He has a genuine passion for property and enjoys helping clients finding their dream homes. Michail thrives in client-facing environments, with excellent communication and interpersonal skills to build rapport and understand individual needs.
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div> */}

                    </Slider >
                </div>
            </Container>
        </section>


    )

}

export default ImageSlider;

import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Slider from "react-slick";
import WorkImg1 from "../../images/campaign_second/our-work-img.jpg";
import WorkImg2 from "../../images/campaign_second/our-work-img-2.jpg";
import WorkImg3 from "../../images/campaign_second/our-work-img-3.jpg";
import "slick-carousel/slick/slick.css";
import { Link } from "gatsby";
import YouTube from '@u-wave/react-youtube';
import getVideoId from 'get-video-id';
import { VideoPlayButton, Logo } from '../../components/icon';
import Modal from 'react-bootstrap/Modal'

const ImageSlider = (props) => {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    dots: true,
                    arrows: false
                }
            }
        ]
    };

    const [show, setShow] = useState(false);

    const [videoID, setVideoID] = React.useState(0);
    const [isPlay, setPlay] = React.useState(0);

    return (
        <section id="work-section" className="section-work">
          <Container className="workSec">
            <div className="head-sec text-center">
              <h2>Our Work</h2>
            </div>
            <div className="work-slider">
                <Slider {...settings}>

                <figure className="work-img">
                    <img src={WorkImg1} attr='Campaign Work - Orlando Reid' />
                    <button className="btn-play" onClick = { e => {setShow(true);setVideoID("idHrCmKWn7I")}}>
                    <VideoPlayButton />
                    </button>
                </figure>

                <figure className="work-img">
                    <img src={WorkImg2} attr='Campaign Work - Orlando Reid' />
                    <button className="btn-play" onClick = { e => {setShow(true);setVideoID("MkPvuLTGaA0")}}>
                    <VideoPlayButton />
                    </button>
                </figure>

                <figure className="work-img">
                    <img src={WorkImg3} attr='Campaign Work - Orlando Reid' />
                    <button className="btn-play" onClick = { e => {setShow(true);setVideoID("0YpY131tRPo")}}>
                    <VideoPlayButton />
                    </button>
                </figure>

                </Slider>
            </div>
          </Container>

          <Modal
              show={show}
              centered={true}
              onHide={() => setShow(false)}
              dialogClassName="modal-90w modal-form modal-fees popup-video"
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                <div className="youtube-player">
                    <YouTube
                        video={videoID}
                        autoplay
                        showRelatedVideos={0}
                        onEnd={e => { setShow(false);setVideoID(0)}}
                        modestBranding={1}
                        onPlaying="test video"
                        controls={0}
                        showCaptions={0}
                        showInfo={0}
                    />
                </div>
              </Modal.Body>
            </Modal>
        </section>


    )

}

export default ImageSlider;
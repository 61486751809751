import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from 'react-bootstrap';
import Header from "../components/CampaignSecond/header";
import Banner from "../components/CampaignSecond/banner";
import GoogleReview from '../components/CampaignSecond/google-reviews'
import StoriesSlider from '../components/CampaignSecond/stories-slider'
import TeamSlider from '../components/CampaignSecond/team-slider'
import WorkSlider from '../components/CampaignSecond/work-slider'
import Counter from '../components/Counter/counter'
import ClaphamEnquiryForm from "../components/forms/clapham-enquiry";
import approach_image_url from "../images/campaign_second/approach_jack.png";
import working_image_url from "../images/campaign_second/working-with-us.jpg";
import Ourwork_image_url from "../images/campaign_second/our-work-img.jpg";
import Modal from 'react-bootstrap/Modal'
import YouTube from '@u-wave/react-youtube';
import getVideoId from 'get-video-id';
import { VideoPlayButton, Logo } from '../components/icon';
import '../scss/pages/campaign-second.scss';

import $ from 'jquery'

import SEO from "../components/Seo/seo";
import { Helmet } from "react-helmet";

const ClaphamEstateAgents = () => {
  const [retPageUrl, setRetPageUrl] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedRetPageUrl = localStorage.getItem("salesFormUrl");
      if (storedRetPageUrl) {
        setRetPageUrl(storedRetPageUrl);
      }
    }

    // Scroll handler function
    const handleScroll = () => {
      let headerElement = document.getElementsByClassName('header2')[0];
      if (headerElement) {
        // If the scroll position is at the top of the page
        if (window.scrollY === 0) {
          headerElement.classList.remove('header-fixed');
        } else if (window.scrollY > 0) {
          headerElement.classList.add('header-fixed');
        }
      }
    };
    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll, true);
    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

  }, []);

  useLayoutEffect(() => {
    // Add a class to the body when the component is mounted
    document.body.classList.add('clapham-page');

    // Cleanup function to remove the class when the component is unmounted
    return () => {
      document.body.classList.remove('clapham-page');
    };
  }, []);

  const [show, setShow] = useState(false);

  const [showVideo, setShowVideo] = React.useState(0);
  const [isPlay, setPlay] = React.useState(0);
  // const videoid = getVideoId(Page.Embed_Video_URL);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <script >
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-5VPPNG9')`}
        </script>
        
      </Helmet>
      <noscript
          dangerouslySetInnerHTML={{
              __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5VPPNG9"
              height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
          }}
      />

      <div className="campaign_second">
        <Header />

        <Banner />

        <SEO title="Clapham Estate Agents" />

        <div className="intro-section intro-text">
          <Container>
            <p className="intro">Orlando Reid is a boutique local agent with unparalleled expertise in Clapham & the surrounding areas.<br></br>We’re agents, but not as you know them.</p>
          </Container>
        </div>

        <div id="about-section" className={`content-block left-img`}>
          <div className={`list`}>
            <Container>
              <Row className={`list-row ellipse_bg`}>
                  <Col lg={6} className="order-md-1 odd">
                      <figure className="video-img">
                        <img src={approach_image_url} attr='Campaign List - Orlando Reid' />
                        <button className="btn-play" onClick = { e => {setPlay(1);setShowVideo(1)}}>
                          <VideoPlayButton />
                        </button>
                        <div className="youtube-player">
                          {
                          showVideo == 1 &&
                            <YouTube
                              video="7SkJ0k5MZQM"
                              autoplay
                              showRelatedVideos={0}
                              onEnd={e => {setPlay(0);setShowVideo(0)}}
                              modestBranding={1}
                              onPlaying="test video"
                              controls={0}
                              showCaptions={0}
                              showInfo={0}
                            />
                          }
                        </div>
                      </figure>
                  </Col>
                  <Col lg={6} className="order-md-2 even">
                      <div className='content'>
                          <h5>Our Approach</h5>
                          <p>As a forward thinking, marketing-first estate agency, we go above and beyond having just a Rightmove listing.</p>
                          <p>Our clients benefit from tailored launch strategies, creative marketing campaigns with professional videography as standard, and our team's expert negotiation skills to maximise market presence.</p>
                          <Link to="#work-section" className={"btn btn-success link-scroll"}>our work</Link>
                      </div>
                  </Col>
              </Row>
            </Container>
          </div>
        </div>

        <section className="grid-block">
          <Container>
            <div className="grid-content-block">
              <div className="heading">
                  <h2>PROOF IS IN THE PUDDING</h2>
              </div>
              <div className="list">
                <Row>
                  <Col lg={4} md={4} className='item data'>
                    <h5>
                      <Counter
                        start="98.9"
                        sym="%"
                        prefix=""
                        duration={15}
                        decimal={1}
                        delay={3}
                      />
                    </h5>
                    <p>Asking price achieved</p>
                  </Col>
                  <Col lg={4} md={4} className='item'>
                    <h5>£1bn+</h5>
                    <p>Sales completed to date</p>
                  </Col>
                  <Col lg={4} md={4} className='item data'>
                    <h5>
                      <Counter
                        start="99"
                        sym="%"
                        prefix=""
                        duration={15}
                        delay={3}
                      />
                    </h5>
                    <p>Smiles</p>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        </section>

        <div id="working-with-us-section" className={`content-block right-img`}>
          <div className={`list`}>
            <Container>
              <Row className={`list-row`}>
                  <Col md={6} className="order-md-2 even">
                    <figure className="video-img full_width">
                      <img src={working_image_url} className="no-bg original" attr='Campaign List - Orlando Reid' />
                      <button className="btn-play" onClick = { e => {setPlay(2);setShowVideo(2)}}>
                        <VideoPlayButton />
                      </button>
                      <div className="youtube-player">
                        {
                        showVideo == 2 &&
                          <YouTube
                            video="H_KLk80xgDk"
                            autoplay
                            showRelatedVideos={0}
                            onEnd={e => {setPlay(0);setShowVideo(0)}}
                            modestBranding={1}
                            onPlaying="test video"
                            controls={0}
                            showCaptions={0}
                            showInfo={0}
                          />
                        }
                      </div>
                    </figure>
                  </Col>
                  <Col md={6} className="order-md-1 odd">
                    <div className='content'>
                      <h5>Working With Us</h5>
                      <p>We understand that every client is unique and work closely with you to achieve the best possible results. We value your trust and consider you not just a client, but a friend.</p>
                      <Link to="#clapham-form-enquiry" className={"btn btn-success link-scroll"}>Join Us</Link>
                    </div>
                  </Col>
              </Row>
            </Container>
          </div>
        </div>

        <GoogleReview />

        <StoriesSlider />

        <WorkSlider />

        <section className="spring-section">
          <Container>
            <div className="text-section">
              <h2>Put Down New Roots This Spring</h2>
              <h3>0.75%<br></br>Sales Fees</h3>
              <div>
                <a href="javascript:;" className="btn btn-default" onClick={() => setShow(true)}>Find out more</a>
              </div>
              <p className="note">T&C Apply*</p>
            </div>
            <Modal
              show={show}
              centered={true}
              onHide={() => setShow(false)}
              dialogClassName="modal-90w modal-form modal-fees"
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  <h2>Exclusive Spring Offer Terms &amp; Conditions</h2>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="modal-text">
                  <strong>1. Offer:</strong>
                  <ul>
                    <li>This offer entitles a limited number of clients to a reduced fee of 0.75% on the sale of freehold properties located in South West London.</li>
                    <li>This offer applies to properties with a valuation price exceeding £1,000,000.</li>
                    <li>This offer is limited to the first 10 clients who accept these terms and conditions.</li>
                  </ul>
                  <strong>2. Eligibility:</strong>
                  <ul>
                    <li>This offer is valid for the sale of freehold properties only.</li>
                    <li>Properties must be located in South West London (specific areas may be defined by Orlando Reid).</li>
                    <li>Orlando Reid reserves the sole right to determine the eligibility of properties for this offer.</li>
                  </ul>
                  <strong>3. Offer Period:</strong>
                  <ul>
                    <li>This offer is available for a limited time and may be withdrawn by Orlando Reid at any time without prior notice.</li>
                  </ul>
                  <strong>4. Exchange Deadline:</strong>
                  <ul>
                    <li>To qualify for the reduced fee, the property must exchange within 6 months of being listed with Orlando Reid.</li>
                  </ul>
                  <strong>5. Client Obligations:</strong>
                  <ul>
                    <li>Clients must accept these terms and conditions in writing to avail of this offer.</li>
                    <li>Clients agree to cooperate fully with Orlando Reid throughout the sales process.</li>
                  </ul>
                  <strong>6. Disclaimer:</strong>
                  <ul>
                    <li>Orlando Reid reserves the right to amend these terms and conditions at any time.</li>
                  </ul>
                </div>
              </Modal.Body>
            </Modal>
          </Container>
        </section>

        <TeamSlider />

        <section className="clapham-form-section">
          <Container>
            <div className="clapham-enquiry">
              <h2>Experience The Difference</h2>
              <h3>Wherever you are in your journey, we’re here for you.</h3>
              <ClaphamEnquiryForm />
            </div>
          </Container>
        </section>

      </div>
    </>
  );
};

export default ClaphamEstateAgents;
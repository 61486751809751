import { Container, Navbar, Nav } from 'react-bootstrap';
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from "gatsby";
import $ from "jquery";
import { LogoMain, LogoReid, Myaccount, Phone } from '../icon';

const HeaderSecond = ({ siteTitle, calendly, phonecall,Enquire_Now }) => {

  if(typeof window !== 'undefined') {
    document.querySelector('body').classList.remove('stop-overflow')
    document.querySelector('body').classList.remove('modal-open')
  }

  const manageClick = () => {
    let clopase = document.querySelector(".collapse")  //basic-navbar-nav 
    clopase.classList.remove("show")
    document.querySelector('body').classList.remove('stop-overflow')
    $('.navbar-collapse').removeClass('mobile-menu-wrapper');

  }
  const showMenu = () => {
    $('.navbar-collapse').addClass('mobile-menu-wrapper');
    $('.mobile-menu-wrapper .navbar-nav').addClass('animate__animated animate__slideInRight');
    let clopase = document.querySelector(".collapse");
    clopase.classList.add("show")
    document.querySelector('body').classList.add('stop-overflow')

  }

  // Mobile menu toggle
  useEffect(() => {
    $(".mobile_nav_menu").each(function () {
      var $this = $(this);
      var subMenu = $this.find('> ul').length;

      if (subMenu) {
        $this.addClass("sub_menu_exist");
      }
    })

    $(".mobile_nav_menu").click(function () {
      //console.log('aaaaaaaaaaa', $(this)[0].className)
      var checkactive = $(this)[0].className;
      if(checkactive.indexOf('active') != -1){
        $(".mobile_nav_menu.sub_menu_exist").removeClass('active');
        $('.sub-nav').stop(true, true).slideUp();
        console.log(checkactive + " found");
       } else {
        console.log(checkactive + "not found");
        $(".mobile_nav_menu.sub_menu_exist").removeClass('active');
        $('.sub-nav').slideUp();
        $(this).toggleClass("active");
        $(this).find('.sub-nav').stop(true, true).slideDown();

       } 
      //$(this).toggleClass("active");
    })

    //Scroll to section
    $(".link-scroll").on("click", function (event) {
      event.preventDefault();
  
      var src = $(this).attr("href"); 
      var target = src.split("#")[1]; // Extract only the fragment (about-section)
  
      if (target) {
        var targetElement = $("#" + target);
        
        if (targetElement.length) {
          var scrollPosition = targetElement.offset().top;
          var minusOffset = 50; // Adjust for fixed header
          if(target === 'work-section') { minusOffset = 120; }
          if(target === 'clapham-form-enquiry') { minusOffset = 250; }
          scrollPosition = scrollPosition - minusOffset;

          $("html, body").animate(
            {
              scrollTop: scrollPosition,
            },
            1500
          );
        }
      }

      let clopase = document.querySelector(".collapse")  //basic-navbar-nav 
      clopase.classList.remove("show")
      document.querySelector('body').classList.remove('stop-overflow')
      $('.navbar-collapse').removeClass('mobile-menu-wrapper');

    });

  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
    <header className="header2 fixed-top">
      <Container>
        <Navbar className="p-0" expand="xl">
          <div className='link-btn d-none d-xl-flex'>
            <Link to={'#clapham-form-enquiry'} className="btn btn-success btn-top link-scroll">Contact Us</Link>
          </div>
          <Navbar.Brand className="p-0" >
            <a href="javascript:;" onClick={scrollToTop}><LogoMain /> <LogoReid /></a>
          </Navbar.Brand>
          {/* <Navbar.Toggle className="p-0" aria-controls="basic-navbar-nav" /> */}
          <button className="p-0 navbar-toggler"
            onClick={
              e => showMenu()
            }
          >
            <span className="navbar-toggler-icon">&nbsp;</span>
          </button>

          
          <Navbar.Collapse id="basic-navbar-nav">
            <button className="close-nav d-xl-none"
              onClick={
                e => manageClick()
              }
            >
              <span>&nbsp;</span>
              <span>&nbsp;</span>
            </button>
            <Nav>
              <ul className="navigation d-xl-flex align-items-xl-center">
                <>
                  <li className="mobile_nav_menu">
                    <Link to={`#about-section`} className={`nav-link link-scroll`}>About</Link>
                  </li>
                  <li className="mobile_nav_menu">
                    <Link to={`#working-with-us-section`} className={`nav-link link-scroll`}>Reviews</Link>
                  </li>
                  <li className="mobile_nav_menu">
                    <Link to={`#casestudies-section`} className={`nav-link link-scroll`}>Case Studies</Link>
                  </li>
                  <li className="mobile_nav_menu">
                    <Link to={`#team-section`} className={`nav-link link-scroll`}>Team</Link>
                  </li>
                  <li className='d-xl-none'>
                    <Link to={'#clapham-form-enquiry'} className="btn btn-top link-scroll">Contact Us</Link>
                  </li>
                </>
              </ul>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
    </>
  )
}

export default HeaderSecond
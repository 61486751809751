import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
// English.
import en from 'javascript-time-ago/locale/en'


const StaticPage = () => {
 
  const postsToShow= [
    {
      comment: "“When I wanted to sell a property I went with the biggest company on the high street, they promised me the earth and delivered exactly 1 viewing in 6 weeks. So then I switched to Armond. Given the other agent already had the property on the market for weeks, Armond had a massive hill to climb - but nevertheless he got 6 viewings within the first week. I’m not sure how he did it but I think it has something to do with the fact that he’s extremely hardworking and diligent and honest and transparent.Just have a chat with him on the phone and I’m sure your “gut feel” will bear out what I’m saying. In short I can’t recommend Armond and the team at Orlando Reid enough.”",
      reviewer: "ASH"
    },
    {
      comment: "“I recently purchased a property through this agency, and I must say, the experience was exceptional. The agent was very helpful and professional throughout the entire process. Their expertise and guidance made my property buying journey smooth and stress-free. This was, without a doubt, the best experience I've ever had in real estate.”",
      reviewer: "ALEX"
    },
    {
      comment: "“Had a great experience at this estate agents. Such personalised service I felt like they were my family at the end. Nothing was too much trouble.”",
      reviewer: "STUART"
    },
    {
      comment: "“Very good experience selling a property with Orlando Reid. From providing a realistic asking price, finding a buyer quickly and involvement across the sale process right up to completion. We live overseas and were selling a share of freehold property which added some complexity to the sale.”",
      reviewer: "KAREN"
    },
    {
      comment: "“Armond has been my estate agent for several years now. I have always found him to be professional, courteous, informative and generous with his time. In my opinion, he’s an indispensable asset to Orlando Reid, and I’m grateful for all the support and service he provides.”",
      reviewer: "GARO"
    },
    {
      comment: "“Had a wonderful experience with Orlando Reid from start to finish when purchasing our first property. They were prompt, communicative and went above and beyond to accommodate extra viewings from pre offer to completion. Would highly recommend Orlando Reid, their professionalism is not often found in the estate agent market and so was very much a pleasure and made our first home purchase as ease free as possible ! Thank you.”",
      reviewer: "ESME"
    }
  ];
 
  
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          dots: true,
          arrows: false
        }
      }
    ]
  };


  return (
    <>
      <section id="reviews-section" className="sec-review">
        <Container>
          <Slider {...settings}>
            {postsToShow.map((review) => {
              return <>
                <div className="card-rating">
                  <p className="comment"> 
                      {review.comment} 
                  </p>
                  <span className="author">{review.reviewer}</span>
                </div>
              </>
            })}
          </Slider> 
        </Container>
      </section> 
    </>
  )
}

export default StaticPage
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Slider from "react-slick";
import StoriesImg1 from "../../images/campaign_second/success_stories_1.jpg";
import StoriesImg2 from "../../images/campaign_second/success_stories_2.jpg";
import StoriesImg3 from "../../images/campaign_second/success_stories_3.jpg";
import "slick-carousel/slick/slick.css";
import { Link } from "gatsby";
import DateFormat from "../format-date";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";

const ImageSlider = (props) => {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        centerPadding: '240px',
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    centerPadding: '192px',
                }
            },
            {
                breakpoint: 1293,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    centerPadding: '140px',
                }
            },
            {
                breakpoint: 991,
                settings: {
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    centerPadding: '0px',
                }
            }
        ]
    };

    return (
        <section id='casestudies-section' className="section-news">
            <Container className="newsSec">
                <div className="head-sec text-center">
                    <h2>Success Stories</h2>
                </div>
                <div className="news-slider">
                    <Slider {...settings}>

                    <div className="news-block text-center">
                        <figure className="news-img">
                            <img src={StoriesImg1} attr='Campaign Stories - Orlando Reid' />
                            <div className='hover-text'>
                                <p>110% Asking Price Achieved<br></br>7 Days on market<br></br>12 Viewings</p>
                            </div>
                        </figure>
                        <div className="news-title">
                            <h3>Elsley Road</h3>
                            <h4>SW11</h4>
                        </div>
                    </div>
                    <div className="news-block text-center">
                        <figure className="news-img">
                            <img src={StoriesImg2} attr='Campaign Stories - Orlando Reid' />
                            <div className='hover-text'>
                                <p>105% Asking Price Achieved<br></br>9 Days on market<br></br>15 Viewings</p>
                            </div>
                        </figure>
                        <div className="news-title">
                            <h3>Battersea Park Road</h3>
                            <h4>SW11</h4>
                        </div>
                    </div>
                    <div className="news-block text-center">
                        <figure className="news-img">
                            <img src={StoriesImg3} attr='Campaign Stories - Orlando Reid' />
                            <div className='hover-text'>
                                <p>114% Asking Price Achieved<br></br>5 Days on market<br></br>18 Viewings</p>
                            </div>
                        </figure>
                        <div className="news-title">
                            <h3>Battersea Rise</h3>
                            <h4>SW11</h4>
                        </div>
                    </div>

                    </Slider >
                </div>
            </Container>
        </section>


    )

}

export default ImageSlider;
